import React from "react"
import styled from "styled-components"
import { Color, Wrapper, Type } from "../../utilities"

const StyledBanner = styled.div`
  ${props =>
    props.image &&
    `
        background-image: url(${props.image});
    `}
  background-size: cover;
  background-position: center center;
  background-color: ${Color.grey};
  height: 250px;
  position: relative;
  display: flex;

  ${Wrapper} {
    height: 100%;
  }
`

const TransOverlay = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 3rem;
  background-color: rgba(0, 128, 189, 0.8);
  width: 35%;
  height: 100%;
  color: ${Color.white};
  font-size: 1.25rem;
  font-family: ${Type.header};

  h1 {
    margin-bottom: 0.5rem;
  }
`

export const InteriorBanner = ({ children, ...props }) => (
  <StyledBanner {...props}>
    <Wrapper>
      {children && (
        <TransOverlay>
          <div>{children}</div>
        </TransOverlay>
      )}
    </Wrapper>
  </StyledBanner>
)
