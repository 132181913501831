import React from "react"
import styled from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { lighten } from "polished"
import { Color } from "../Color"
import { Wrapper } from "../Wrapper"

const SECTION_MODIFIERS_CONFIG = {
  blue: () => `
    background: ${Color.primary};
    color: ${Color.white};
    border-top: 30px solid ${Color.white};
    border-bottom: 30px solid ${Color.white};
  
    h1, h2, h3, h4, h5, h6 {
      color: ${Color.white};
    }
    `,

  image: () => `
    background-image: ${props =>
      props.image ? `url(${props.image})` : "none"};
    background-size: cover;
  `,
}

export const StyledSection = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${Color.primary};
    margin-bottom: 1.5rem;
    font-weight: bold;
  }

  position: relative;

  img {
    max-width: 100%;
  }

  ${applyStyleModifiers(SECTION_MODIFIERS_CONFIG)}
`

export const SectionHeader = styled.div`
  padding: 0.5rem 1.5rem;
  background: ${lighten(0.6, Color.grey)};
  border-left: 5px solid ${Color.primary};
  color: ${Color.primary};
  font-size: 1.1rem;
  font-weight: 700;
`

export const SectionBody = styled.div`
  padding: 3rem;
  height: 100%;
  box-sizing: border-box;
  font-size: 18px;

  ${Wrapper} {
    height: 100%;
  }
`

export const SectionSmall = styled.div`
  max-width: 750px;
  width: 90%;
  margin: auto;
`

export const Section = ({ header, children, ...props }) => (
  <StyledSection {...props}>
    {header && <SectionHeader>{header}</SectionHeader>}
    <SectionBody>
      <Wrapper>{children}</Wrapper>
    </SectionBody>
  </StyledSection>
)
