import React from "react"
import styled from "styled-components"

const HiddenText = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const SROnly = ({ children }) => (
  <HiddenText className="sr-only">{children}</HiddenText>
)
