import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

import { Color, Type, Wrapper, Media, Button } from "../../utilities"

import wave from "../../../images/header-wave.png"

const StyledNavbar = styled.nav`
  color: ${Color.primary};
  position: relative;
  background: ${Color.primary};

  ${Media.below.desktop`
    background: ${Color.white};
  `}
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: flex-end;

  ${Media.below.mobile`
    flex-wrap: wrap;
    transition: all 0.2s ease;

    &.closed {
      overflow: hidden;
      height: 0;
    }
  `}
`

const StyledLink = styled(Link)`
  text-align: center;
  flex-basis: 130px;
  align-items: center;
  display: flex;
  padding: 1rem;
  justify-content: center;
  color: ${Color.primary};
  text-decoration: none;
  font-family: ${Type.header};
  font-size: 1.25em;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 10;
  overflow: hidden;

  &:hover {
    color: ${Color.red};
  }

  ${Media.below.mobile`
    flex: 100%;

    &:hover {
      transform: none;
    }
  `}
`

const NavButton = styled(Button)`
  width: 100%;
  margin: auto;
  background: ${Color.white};
  color: ${Color.primary};
  font-size: 1rem;
  padding: 1.5rem !important;
  display: none;
  border-radius: 3px !important;

  &:hover {
    transform: none;
    border-radius: none;
  }

  &.open {
    background-color: ${Color.white} !important;
  }

  ${Media.below.mobile`
    display: block;
  `}
`

const NavIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`

const WaveContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: -10px;
  height: 150px;

  img {
    width: 100%;
  }

  ${Media.below.desktop`
    display: none;
  `}
`

const links = [
  {
    url: "/",
    label: "Home",
  },
  {
    url: "/pool-build/",
    label: "Pool Build",
  },
  {
    url: "/pool-service/",
    label: "Pool Service",
  },
  {
    url: "/about-us/",
    label: "About Us",
  },
  {
    url: "/contact-us/",
    label: "Contact",
  },
]

const Navbar = props => {
  const [open, setOpen] = useState(false)

  return (
    <StyledNavbar {...props}>
      <NavButton onClick={() => setOpen(!open)} className={`${open && "open"}`}>
        <NavIcon icon={!open ? faBars : faTimes} />
        {!open ? `Open ` : `Close `}
        Navigation
      </NavButton>
      <WaveContainer>
        <img src={wave} alt="White Pool Wave" />
      </WaveContainer>
      <StyledWrapper className={`${!open && "closed"}`}>
        {links.map((link, ix) => (
          <StyledLink key={ix} to={link.url}>
            {link.label}
          </StyledLink>
        ))}
      </StyledWrapper>
    </StyledNavbar>
  )
}

export default Navbar
