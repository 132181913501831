import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { Color, Wrapper, ButtonLink, Type, Media } from "../../utilities"

const StyledSlider = styled.section`
  height: 550px;
  position: relative;
  margin-bottom: 250px;

  ${Media.below.desktop`
    margin-bottom: 200px;
  `}
`

const StyledSlide = styled.div`
  background-image: ${props => (props.image ? `url(${props.image})` : "none")};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  ${props =>
    props.active &&
    `
    opacity: 1;
  `}
`

const SlideOverlay = styled.div`
  background: ${Color.primary};
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  align-content: flex-start;
  padding: 2rem;
  text-align: center;
  color: ${Color.white};
  line-height: 200%;
  font-family: ${Type.header};
  height: 250px;

  > div {
    width: 100%;
  }

  h1,
  h2,
  h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.25rem;
  }

  ${ButtonLink} {
    margin-top: 1.5rem;
  }

  ${Media.below.desktop`
    width: 100%;
    line-height: 150%;

    h1, h2, h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }

    ${ButtonLink} {
      margin-top: 1rem;
    }
  `}
`

const SlideContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  height: 250px;
  bottom: -250px;
  position: absolute;
  width: 100%;

  ${Media.below.desktop`
    justify-content: center;
    height: 200px;
    bottom: -200px;
    width: 100%;
  `}
`

const Slide = ({ children, ...props }) => (
  <StyledSlide {...props}>
    <SlideContainer>
      {children && (
        <SlideOverlay>
          <Wrapper>{children}</Wrapper>
        </SlideOverlay>
      )}
    </SlideContainer>
  </StyledSlide>
)

const renderSlides = ({ slides, state }) =>
  slides.map((slide, ix) => {
    return (
      <Slide image={slide.image} active={state === ix} key={ix}>
        {slide.content()}
      </Slide>
    )
  })

export const Slider = ({ slides = [] }) => {
  const [state, setState] = useState(0)

  // Slide Show
  useEffect(() => {
    const nextSlide = state >= slides.length - 1 ? 0 : state + 1
    const timeout = setTimeout(() => setState(nextSlide), 6000)

    return () => clearTimeout(timeout)
  }, [state])

  return <StyledSlider>{renderSlides({ slides, state })}</StyledSlider>
}
