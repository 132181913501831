import { Component } from "react"
import ReactDOM from "react-dom"

let portalRoot

class Portal extends Component {
  constructor() {
    super()
    if (typeof document !== "undefined") {
      portalRoot = document.getElementById("portal")
      this.el = document.createElement("div")
    }
  }

  componentDidMount() {
    portalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el)
  }

  render() {
    const { children } = this.props
    return ReactDOM.createPortal(children, this.el)
  }
}

export default Portal
