import React from "react"
import styled from "styled-components"
import { Media } from "../../utilities"

const StyledGrid = styled.div`
  display: flex;
  width: 100%;
  height: 450px;

  ${Media.below.tablet`
    display: block;
    height: auto;
  `}
`

const ImageBlock = styled.div`
  flex: 1;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-image: ${props => (props.image ? `url(${props.image})` : "none")};
  background-repeat: no-repeat;

  ${Media.below.tablet`
    height: 200px;
  `}
`

export const ImageGrid = ({ images = [], ...props }) => (
  <StyledGrid {...props}>
    {images.map((im, ix) => (
      <ImageBlock image={im} key={ix} />
    ))}
  </StyledGrid>
)
