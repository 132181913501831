import React from "react"
import styled, { keyframes } from "styled-components"

import back from "../../../images/contact-banner.jpg"
import tube from "../../../images/tube.png"

import {
  ButtonLink,
  Col,
  Color,
  Flex,
  Media,
  Section,
  Type,
} from "../../utilities"

const StyledContact = styled(Section)`
  font-family: ${Type.header};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 150%;
  height: 800px;
  background-image: url(${back});
  background-size: cover;
  color: ${Color.white};

  h3 {
    color: ${Color.white};
    font-size: 36px;
  }

  ${ButtonLink} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Media.below.tablet`
    text-align: center;
  `}
`

const StyledFlex = styled(Flex)`
  align-items: center;
  align-content: center;
  height: 100%;
`

const tubeSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const TubeContainer = styled.div`
  padding: 2rem;
  img {
    animation-name: ${tubeSpin};
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
  }

  ${Media.below.tablet`
    margin-top: 2rem;
  `}
`

export const ContactSection = props => {
  const { children } = props
  return (
    <StyledContact modifiers={["image"]}>
      <StyledFlex>
        <Col>
          <h3>Outdoor Fun is Calling Your Name!</h3>
          <p>
            {children ?? (
              <>
                Start planning the backyard of your dreams.
                <br />
                Contact Hunt Pools &amp; Service today.
              </>
            )}
          </p>
          <ButtonLink to="/contact-us/" modifiers={["white"]}>
            Contact Us Today!
          </ButtonLink>
        </Col>
        <Col>
          <TubeContainer>
            <img src={tube} alt="Orange and White Pool Tube" />
          </TubeContainer>
        </Col>
      </StyledFlex>
    </StyledContact>
  )
}
