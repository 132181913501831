import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import Header from "../Header"
import Footer from "../Footer"

import { GlobalStyles } from "../../elements"
import { Color } from "../../utilities"

const Body = styled.div`
  background: ${Color.white};
  line-height: 200%;

  a {
    color: ${Color.blue};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const { GATSBY_CLARITY_ID } = process.env

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            phone
            email
            address_one
            address_two
            social {
              facebook
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet>
          <script
            id="clarity-tag"
            key="clarity"
            type="text/javascript"
            src={`https://www.clarity.ms/tag/${GATSBY_CLARITY_ID}`}
            async
          />
        </Helmet>
        <GlobalStyles />
        <Header siteMetadata={data.site.siteMetadata} />
        <Body>
          {typeof children === "function"
            ? children({ siteMetadata: data.site.siteMetadata })
            : children}
        </Body>
        <Footer siteMetadata={data.site.siteMetadata} />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
