import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { Color, Wrapper, Col, Grid, Flex, SROnly } from "../../utilities"

import logo from "../../../images/logo.png"
import { darken } from "polished"

const StyledFooter = styled.div`
  color: ${Color.white};
  padding: 0 0 5rem 0;
  font-size: 1rem;
  line-height: 200%;
  position: relative;

  a {
    text-decoration: none;
    color: ${Color.white};
    &:hover {
      text-decoration: underline;
      color: ${Color.white};
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`

const BBBContainer = styled.div`
  margin-top: 1rem;
  background: white;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 10px;
`

const LogoCol = styled(Col)`
  font-style: italic;
`

const ContactCol = styled(Col)`
  h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
`

const FooterGrid = styled(Grid)`
  grid-gap: 2.5rem;
`

const FooterTop = styled.div`
  background-color: ${Color.grey};
  padding-bottom: 1rem;
  padding-top: 3rem;
`

const FooterBottom = styled.div`
  width: 100%;
  background: ${darken(0.1, Color.grey)};
  color: ${Color.white};
  font-size: 0.8rem;
  padding: 0.8rem 0;
`

const SocialIcons = styled.div`
  font-size: 1.25rem;
  text-align: right;

  svg {
    margin-right: 1rem;
  }
`

const Footer = ({ siteMetadata = {} }) => {
  const { title, phone, email, social, address_one, address_two } = siteMetadata
  return (
    <StyledFooter>
      <FooterTop>
        <Wrapper>
          <FooterGrid modifiers={["thirds", "gap"]}>
            <LogoCol>
              <img src={logo} alt={title} />
              <BBBContainer>
                <a
                  href="https://www.bbb.org/us/oh/perrysburg/profile/pool-contractors/hunt-pools-service-0422-211002457/#sealclick"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    src="https://seal-toledo.bbb.org/seals/blue-seal-293-61-bbb-211002457.png"
                    style={{ border: 0 }}
                    alt="Hunt Pools & Service BBB Business Review"
                  />
                </a>
              </BBBContainer>
            </LogoCol>
            <ContactCol>
              <h2>Contact</h2>
              <h3>Address:</h3>
              <p>
                {address_one}
                <br />
                {address_two}
              </p>
              <h3>Phone:</h3>
              <p>{phone}</p>
              <h3>Email Service:</h3>
              <p>{email}</p>
            </ContactCol>
            <Col>
              <h2>Location</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2986.427374626254!2d-83.64140134829435!3d41.538342894483236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883c76f96faa717d%3A0xd872690513326301!2s26610%20Eckel%20Rd%20%233B%2C%20Perrysburg%2C%20OH%2043551!5e0!3m2!1sen!2sus!4v1577720945516!5m2!1sen!2sus"
                height="300"
                title="Google Maps"
                frameBorder="0"
              ></iframe>
            </Col>
          </FooterGrid>
        </Wrapper>
      </FooterTop>
      <FooterBottom>
        <Wrapper>
          <Flex>
            <Col>
              Copyright {new Date().getFullYear()} {title} | All Rights Reserved
            </Col>
            <Col>
              <SocialIcons>
                {social.facebook && (
                  <a
                    href={social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit Hunt Pools & Services Facebook Page"
                    aria-label="Visit Hunt Pools & Services Facebook Page"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                    <SROnly>Hunt Pools & Services Facebook Page</SROnly>
                  </a>
                )}
              </SocialIcons>
            </Col>
          </Flex>
        </Wrapper>
      </FooterBottom>
    </StyledFooter>
  )
}

export default Footer
