export const Color = {
  white: "#FFF",
  primary: "#0080bc",
  secondary: "#ff0000",
  black: "#000",
  grey: "#525252",
  red: "#ff0000",
  green: "#34ab2e",
  grey: "#373738",
}
